@use "~bootstrap/scss/bootstrap.scss";
@use "../variables" as variables2;

.app-bottombar {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  max-width: 1100px;
  height: 210px;
  background: bootstrap.$white;
  border: 1px solid bootstrap.$border-color;
  border-width: 1px 0 0 1px;
  transition: all 0.2s ease-in;
  font-size: 1rem;
  z-index: 2;

  .btn {
    border-bottom-width: 3px;
    box-shadow: none !important; 
    &.active {
      border: none !important;
      background: #fff !important;
      border-bottom: 3px solid bootstrap.$primary !important;
    }
  }

  .content {
    overflow: auto;
    padding: 0 0 0 1em;

    .details {
      display: none;
      max-height: 100%;
      overflow: auto;
      
      .device-attribute,
      .device-sensor {
        font-size: 0.8em;
        overflow: hidden;
        border-bottom: 1px solid variables2.$secondary;
        padding: 0.3em 0;
        .name {
          font-weight: bold;
          float: left;
          padding-right: 5px;
          width: 100px;
          padding-left: 5px;
          text-transform: capitalize;
        }
        .value {
          float: left;
        

        }
      }

      .device-sensor{
        
        .name{
          padding-left: 1px;
          width: 100px;
          .sensor{
            height: 1em;
            float: left;
            margin: 0;
            margin-right: 5px;
            position: relative;
            top: -3px;
          }
        }
      }

     



      &.active {
        display: block;
      }

      .googleStreetView {
        height: 100%;
        min-width: 100%;
        min-height: 100%;
        position: relative;
        > a {
          min-width: 100%;
          width: 100%;
          height: 100%;
          min-height: 100%;
          background-size: cover;
          background-position: center center;
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
        }
      }
    }
  }

  .title {
    font-size: 1.1em;
    background: #e7e7e7;
    padding: 0.3em 1em;
    margin: 0;
    line-height: 1.6em;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
    font-weight: bold;
  }

  .title2 {
    font-size: 1.1em;
    background: #ffffff;
    padding: 0.3em 1em;
    margin: 0;
    line-height: 1.6em;
    white-space: nowrap;
    overflow: hidden !important;
    text-overflow: ellipsis;
  }

  .control {
    font-size: 1.5em;
    position: absolute;
    height: 1.5em;
    width: 3em;
    top: -1.5em;
    right: 20%;
    margin-top: -0.5px;
    background: bootstrap.$white;
    border-radius: 0 0 5px 0;
    line-height: 1.5em;
    text-align: center;
    border: 1px solid bootstrap.$border-color;
    border-width: 1px 1px 0 1px;
    opacity: 1;
    cursor: pointer;
    transition: all 0.2s ease-in;

    border-radius: 5px 5px 0 0;

    .button:before {
      content: "\F0140";
      font-family: "Material Design Icons";
      opacity: 0.8;
    }

    &:hover {
      /*width: 1.5em;
      right: -1.5em;*/
      .button:after {
        opacity: 1;
      }
    }
  }

  .dropdown-menu {
    /*position: absolute !important;
    z-index: 999999999;
    transform: none !important;
    left: 20px !important;*/
    //transform: translate3d(317px, 146px, 0px)!important;
    left: 10px!important;
    top: 0px!important;
    bottom: 50px!important;
    right: 120px!important;
  }

  //state
  &.toggled {
    transform: translateY(100%);
    .control {
      .button:before {
        content: "\F0143";
        font-family: "Material Design Icons";
        opacity: 0.8;
      }
    }
  }

  @media screen and (min-width: 768px) {
    .details {
      &.d-tab.active{
        display: block !important;
        
      }
      &.d-street{
        display: block !important;
      }
    }
  }

  @media screen and (min-width: variables2.$mobile-break) {
    width: calc(100% - 370px);
  }
}
