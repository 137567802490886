@use "sass:meta";
@use "variables";
@use "animations";
@use "~bootstrap/scss/bootstrap.scss";

@import "~@fortawesome/fontawesome-free/css/all.css";
@include meta.load-css("~@mdi/font/scss/materialdesignicons");
//@import "~ol/ol.css";
@include meta.load-css("~bootstrap/scss/bootstrap.scss");
@import "~leaflet/dist/leaflet.css";
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
@include meta.load-css("~sweetalert2/src/sweetalert2.scss");
@import "~izitoast/dist/css/iziToast.css";
@import "~react-bootstrap-typeahead/css/Typeahead.css";
@import "~react-bootstrap-typeahead/css/Typeahead-bs4.css";
@import "~leaflet-draw/dist/leaflet.draw.css";
@import "~react-datetime/css/react-datetime.css";
@include meta.load-css("openlayers");
@import "~react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
@import "~animate.css/animate.min.css";
//@import '~react-virtualized/styles.css';
@include meta.load-css("hamburgers");
@include meta.load-css("components/main");
@include meta.load-css("pages/main");
@include meta.load-css("devicestatus");
@include meta.load-css("custom");
html,
body {
  height: 100%;
  min-height: 100%;
  font-family: "Roboto", Arial, Helvetica, sans-serif;
}

body {
  background: variables.$dark-bg;
  background-image: url("/img/logo-light.svg"), url("/img/loader-01.svg");
  background-repeat: no-repeat, no-repeat;
  background-position: calc(50%) calc(50% + 20px), calc(50%) calc(50% - 75px);
  background-size: 300px, 100px;
  font-weight: 300;
}

#root,
#appWrapper,
#layoutWrapper,
.page {
  height: 100%;
  /*background: $gray-100;*/
  position: relative;
  min-height: 450px;
}

#contentWrapper {
  overflow: auto;
  background: variables.$dark-bg url("/img/bg-01.jpg") center center no-repeat;
  background-size: cover;
}

#overlayLogo {
  z-index: 1;
  position: fixed;
  top: 10px;
  left: 16px;
}

.main {
  display: flex;
  position: relative;
  min-height: 450px;
}

.app-header {
  transition: all 0.2s ease-in;
}

.map-full-screen {
  .app-header {
    transform: translateY(-4rem);
  }
  .app-sidebar {
    transform: translateX(-150%);
  }
}

tr.selected{
  background-color: variables.$secondary;
}
.nopadding {
  padding: 0 !important;
}
.custom-select-sm{
  margin-left: 0px!important;
}

.btn-red {
    color: #fff;
    background-color: #C22E2F !important;
}

.btn-green {
  color: #fff;
  background-color: #36863B !important;
}

.btn-blue {
  color: #fff;
  background-color: #4285f4 !important;
}

.dropdown-blue {
  .dropdown-toggle::after {
    display: none !important;
  }
  .btn {
    color: #fff;
    background: #4285f4 !important;
    width: 2rem !important;
    height: 2rem !important;
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0 !important;
    margin-bottom: 0.35rem !important;
    margin-top: 0.35rem !important;
  }
}

.icon-btn-blue {
  //color: #ffffff!important;
  //background-color: #4285f4 !important;
  min-width: 1rem !important;
  width: 2rem;
  height: 2rem;
  margin-bottom: 0.35rem !important;
  margin-top: 0.35rem !important;
  margin-right: 0.5rem !important;
  padding: 0;
}

.css-jrrpff.Layout-root {
  width: 100%;
  height: 100%;
  margin: 0;
  overflow-y: auto;
}
.css-vr9er9-MuiModal-root-MuiDrawer-root .Overlay-fullSize {
  height: 100%!important;
  width: 100%!important;
}