@use "~bootstrap/scss/bootstrap.scss";

.vehicle-tooltip {
  background: #fff !important;
  border-radius: 0;
  box-shadow: none;
  top: -22px;
  border: 1px solid #ccc;
  font-size: .8em;
  opacity: 1 !important;
  &:after,
  &:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-color: rgba(136, 183, 213, 0);
    border-top-color: #fff;
    border-width: 10px;
    margin-left: -10px;
  }
  &:before {
    border-color: rgba(194, 225, 245, 0);
    border-top-color: #ccc;
    border-width: 11px;
    margin-left: -11px;
  }
  &:hover{
    z-index: 999999;
  }
}

.vehicle-popup {
  border-radius: 0;
  box-shadow: none;
  margin-bottom: 5px;
  .leaflet-popup-content-wrapper {
    border-radius: 15px;
    box-shadow: none;
    border: 1px solid #ccc;
    padding: 0;

    &:after,
    &:before {
      top: 100%;
      left: 50%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      margin-top: -1px;
    }
    &:after {
      border-color: rgba(136, 183, 213, 0);
      border-top-color: #fff;
      border-width: 10px;
      margin-left: -10px;
    }
    &:before {
      border-color: rgba(194, 225, 245, 0);
      border-top-color: #ccc;
      border-width: 11px;
      margin-left: -11px;
    }

    .leaflet-popup-content {
      padding: 0;
      margin: 0;
    }
  }

  .leaflet-popup-tip-container {
    .leaflet-popup-tip {
      display: none;
    }
  }

  .tooltip-info {
    overflow: auto;
    max-height: 30vh;
    .title {
      font-size: 1.4em;
      background: #ededed;
      padding: 5px;
      padding-right: 1.4em;
      font-weight: bold;
    }
  }
}

.geofence-name {
  color: bootstrap.$white;
  opacity: 1 !important;
  white-space: nowrap;
  border: 2px solid transparent;
  top: -1em;
  z-index: 1!important;

  .name {
    padding: 1px 5px;
  }
}

.leaflet-fade-anim .leaflet-popup {
  transition: none;
}
